<template>
  <div>
    <div class="d-flex flex-row justify-content-between p-3 card" style="cursor:pointer;">
      <div class="d-flex align-items-center w-100">
        <MaidAvatar :url="booking.maid.user.image_url" :level="booking.maid.level" class="mr-4" />
        <div class="d-inline-block">
          <span class="mb-0">{{ maidName }}</span>
          <b-badge pill :variant="badgeVaraint" class="ml-2 font-weight-normal">{{
            badgeLabel
          }}</b-badge>
          <p class="mb-0 text-primary">{{ serviceLabel }}</p>
          <b-progress
            :value="booking.booked_times"
            :max="booking.multi_times"
            variant="warning"
            style="height: 0.5rem;"
            class="my-2"
          />
          <p class="text-warning">
            {{ `${booking.booked_times}/${booking.multi_times} ${$t('form_label.credits_used')}` }}
          </p>
        </div>
      </div>
      <div>
        <b-img :src="require('../assets/images/icons/multi-package-yellow.svg')" />
      </div>
    </div>
    <div :id="`${booking.id}`" class="p-3 bg-light">
      <p class="text-danger mb-2" v-if="booking.not_booked_times > 0">
        <i class="fas fa-exclamation-triangle mr-2"></i>
        {{ $t('form_label.you_have_not_booked') }} {{ booking.not_booked_times }}
        {{ $t('form_label.credits') }}
      </p>
      <p class="text-danger mb-2">
        {{ $t('form_label.expiry_date') }} :
        <template v-if="booking.expired_datetime">
          {{ booking.expired_datetime | formatDate(dateFormatPattern) }}
        </template>
        <template v-else
          >-</template
        >
      </p>
      <p class="mb-2">
        {{ $t('form_label.start_date') }} :
        <template v-if="booking.started_datetime">
          {{ booking.started_datetime | formatDate(dateFormatPattern) }}
        </template>
        <template v-else
          >-</template
        >
      </p>
      <p class="mb-2" v-if="payment">
        {{ $t('form_label.purchase_date') }} :
        {{ payment.create_datetime | formatDate(dateFormatPattern) }}
      </p>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import { STATUS } from '../config/booking'
import BookingStatus from '../components/BookingStatus'
import moment from 'moment'
import MaidAvatar from './MaidAvatar'

@Component({
  props: {
    booking: Object
  },
  components: { BookingStatus, MaidAvatar }
})
export default class MultiPackageItem extends Vue {
  get dateFormatPattern() {
    return this.$i18n.locale === 'th' ? `DD MMM YYYY, H:mm` : `MMM DD, YYYY, H:mm`
  }

  get maidName() {
    if (this.booking.maid) {
      const nickname = this.booking.maid.user.nick_name
        ? `"${this.booking.maid.user.nick_name}" `
        : ``
      return `${nickname}${this.booking.maid.user.first_name} ${this.booking.maid.user.last_name}`
    } else {
      return ``
    }
  }
  get isTimeoutOfAppproval() {
    if (!this.booking.payment) return false
    const isTimeoutOfAppproval =
      moment().diff(moment(this.booking.payment.create_datetime), 'seconds') > 60 * 60 * 24
    return isTimeoutOfAppproval
  }


  get isMultiPackageCompleted() {
    const bookingCalendar = [...this.booking.booking_calendars]
    this.booking.booking_changed_maid.forEach(({ booking_calendars }) => {
      bookingCalendar.push(...booking_calendars)
    })

    const isAllPassed = bookingCalendar.every(booking => moment() > moment(booking.end_datetime))

    const isAllApproved = bookingCalendar.every(({ status }) => status === STATUS.approved)

    const isAllCreditsUsed = this.booking.multi_times === bookingCalendar.length

    return isAllPassed && isAllApproved && isAllCreditsUsed
  }

  get badgeVaraint() {
    if (this.isMultiPackageCompleted) return `dark`
    if (this.isApproved) return `warning`
    if (this.isCanceled|| this.isTimeoutOfAppproval) return `danger`
    return `primary`
  }

  get badgeLabel() {
    if (this.isMultiPackageCompleted) return this.$t('status.multi_package_completed')
    if (this.isApproved) return this.$t('status.assignment_confirmed')
    if (this.isCanceled || this.isTimeoutOfAppproval) return this.$t('status.assignment_unsuccessful')
    return this.$t('status.payment_confirmed')
  }

  get isCanceled() {
    return this.booking.booking_calendars.some(booking => booking.status === STATUS.canceledByMaid)
  }

  get isApproved() {
    return this.booking_calendars.some(({ status }) => status === STATUS.approved)
  }

  get serviceLabel() {
    const serviceLabel = []
    this.booking.services.forEach(service => {
      const serviceLower = service.toLowerCase()
      serviceLabel.push(this.$t(`services.${serviceLower}`))
    })
    return serviceLabel.join(' / ')
  }

  get booking_calendars() {
    const bookingChangedMaid = []
    this.booking.booking_changed_maid.forEach(({ booking_calendars }) => {
      booking_calendars.forEach(booking => {
        bookingChangedMaid.push(booking)
      })
    })

    return [...bookingChangedMaid, ...this.booking.booking_calendars].sort((a, b) => {
      const aStartDate = moment(a.start_datetime)
      const bStartDate = moment(b.start_datetime)
      return aStartDate - bStartDate
    })
  }

  get payment() {
    return this.booking.payment
      ? this.booking.payment // ไม่ได้มีการเปลี่ยนแม่บ้าน
      : this.booking.booking_changed_maid.find(({ payment }) => !!payment) // มีการเปลี่ยนแม่บ้าน
  }
}
</script>

<style lang="css" scoped>
.avatar {
  width: 64px;
  height: 64px;
  border-radius: 64px;
}
</style>
