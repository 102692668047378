<template>
  <b-container class="container-content">
    <div class="page-header">
      <h4 class="page-header-text">{{ $t('nav.my_multi_package') }}</h4>
    </div>
    <div v-if="myMultiPackageList.length !== 0">
      <ul class="list-unstyled">
        <li
          v-for="(booking, index) in myMultiPackageList"
          :key="index"
          class="mb-4"
          @click="onSelectBooking(booking)"
        >
          <MultiPackageItem :booking="booking" />
        </li>
      </ul>
    </div>
    <div v-else-if="isFetchingDataUpcoming">
      <template v-for="i in 8">
        <MultiPackageLoader :key="i" class="mb-4" />
      </template>
    </div>
    <div v-else class="text-center py-4">
      <p v-html="$t('no_data.no_multi_package')"></p>
      <b-link :to="{ name: 'Maids' }">{{ $t('button.view_all_maids') }}</b-link>
    </div>
  </b-container>
</template>

<script>
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ApiClient from '../services'
import MultiPackageItem from '../components/MultiPackageItem'
import MultiPackageLoader from '../components/MultiPackageLoader'

@Component({ components: { MultiPackageItem, MultiPackageLoader } })
export default class MyMultiPackage extends Vue {
  packageList = []
  isFetchingDataUpcoming = false

  async mounted() {
    this.$store.commit('booking/resetState')
    this.isFetchingDataUpcoming = true
    try {
      const result = await ApiClient.myMultiPackageList()
      this.packageList = result.data.booking
    } catch (e) {
      console.log(e.response)
    }
    this.isFetchingDataUpcoming = false
    await this.$store.commit('maid/resetMaidsList')
  }

  getServiceLabel(sevicesList) {
    return sevicesList.join(' / ')
  }

  // async getMyMultiPackageUpcoming(){
  //   if (this.isFetchingDataUpcoming) return
  //   this.isFetchingDataUpcoming = true
  //   try {
  //     const result = await ApiClient.myMultiPackageList()
  //     this.packageList = result.data.booking
  //   } catch (e) {
  //     console.log(e.response)
  //   }
  //   this.isFetchingDataUpComing = false
  //   await this.$store.commit('maid/resetMaidsList')
  // }

  get myMultiPackageList() {
    // return this.packageList.filter(booking => booking.maid !== null)
    return this.packageList
  }

  onSelectBooking(booking) {
    this.$router.push({
      name: 'MyBookingMultiPackage',
      params: {
        id: booking.id,
      },
    })
  }
}
</script>
<style lang="css" scoped>
.avatar {
  width: 64px;
  height: 64px;
  border-radius: 64px;
}
</style>
