<template>
  <div class="card p-3 text-center">
    <vue-content-loading :width="200" :height="30">
      <circle cx="10" cy="15" r="9" />
      <rect x="30" y="4" rx="1" ry="1" width="50" height="2.5" />
      <rect x="30" y="11" rx="1" ry="1" width="25" height="2.5" />
      <rect x="30" y="17" rx="1" ry="1" width="80" height="2.5" />
      <rect x="30" y="23" rx="1" ry="1" width="40" height="2.5" />
    </vue-content-loading>
  </div>
</template>

<script>
import VueContentLoading from 'vue-content-loading'
export default {
  components: { VueContentLoading }
}
</script>

<style></style>
